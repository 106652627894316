<template>
  <div class="banner-manage base-container">
    <div class="filter">
      <div class="l">
        <el-input
          placeholder="昵称、电话、公司"
          v-model="queryData.keyword"
          style="width: 320px;margin-right: 20px;"
          class="f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>

        <el-select
          class="select"
          v-model="queryData.date"
          placeholder="日期"
          @change="selectDate"
        >
          <el-option
            v-for="(item, index) in dateList"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="r">
        <el-button round @click="toggleDownloadDialog = true"
          ><i class="el-icon-s-order"></i> 下载中奖名单</el-button
        >
      </div>
    </div>
    <el-table
      class="body"
      style="margin-top: 10px;"
      :data="list"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55px"
        :selectable="handleCheckboxDisabled"
      ></el-table-column>
      <el-table-column label="报名用户" width="500px">
        <template slot-scope="scope">
          <div class="user-info">
            <p>{{ scope.row.realName }}</p>
            <p class="color9">
              {{ scope.row.contactPhone | formatPhone }}({{
                scope.row.carrier
              }})，{{ scope.row.companyName }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="curDataLable" sortable prop="helpCount">
        <template slot-scope="scope">
          <p class="color9" :class="{ orange: scope.row.helpCount >= 39 }">
            {{ scope.row.helpCount }}人
          </p>
        </template>
      </el-table-column>
      <el-table-column label="奖品名称">
        <template slot-scope="scope">
          <p v-if="scope.row.winningAmount" class="red">
            {{ scope.row.winningAmount }}元红包
          </p>
          <p v-else class="color9">-</p>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <div class="l">
        <el-dropdown
          ref="winGiftDropdown"
          class="ml20"
          trigger="click"
          @command="handleWinClick"
        >
          <el-button type="primary" plain>批量设置中奖</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="88">88元红包</el-dropdown-item>
            <el-dropdown-item command="178">178元红包</el-dropdown-item>
            <el-dropdown-item command="268">268元红包</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageNumChange"
        :current-page.sync="queryData.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryData.pageSize"
        layout="sizes, prev, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="toggleDownloadDialog"
      title="下载中奖名单"
      width="330px"
    >
      <div class="doanload-dialog">
        <div class="downlost-list">
          <div
            v-for="(item, index) in fileRecords"
            :key="index"
            class="item"
            @click="downloadWinFile(item)"
          >
            <svg-icon class="svg" icon-class="txt"></svg-icon>
            <p>{{ item.date }}</p>
          </div>
        </div>
        <el-button class="g-btn" type="text" @click="exportWinningList"
          ><i class="el-icon-refresh"></i> 生成{{ yesterday }}数据</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getJoinUserList,
  getActivityDateStr,
  editWinning,
  getWinningFileDateStr,
  exportWinningList
} from "@/api/activity";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
  data() {
    return {
      queryData: {
        date: "",
        keyword: "",
        pageNum: 1,
        pageSize: 10,
        sortType: 2 //排序 1 正序 2 倒叙
      },
      status: {
        0: "草稿",
        1: "上架"
      },
      winData: {
        //设置中奖
        amount: 0,
        date: "",
        registIdList: []
      },
      list: [],
      dateList: [], //助力日期
      pageSizes: [5, 10, 20, 30, 50],
      total: 0,
      toggleDownloadDialog: false,
      curDataLable: "",
      fileRecords: []
    };
  },
  computed: {
    yesterday() {
      return moment()
        .subtract(1, "days")
        .format("MM月DD日");
    }
  },
  created() {
    this.getActivityDateStr();
    this.getWinningFileDateStr();
  },
  methods: {
    selectDate(date) {
      //选择时间
      this.curDataLable = date.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2月$3日");
      this.getJoinUserList();
    },
    search() {
      this.queryData.pageNum = 1;
      this.getJoinUserList();
    },
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.getJoinUserList();
    },
    pageNumChange(num) {
      this.queryData.pageNum = num;
      this.getJoinUserList();
    },
    handleCheckboxDisabled(row) {
      if (row.helpCount >= 39) {
        return true;
      } else {
        return false;
      }
    },
    async exportWinningList() {
      //生成
      const res = await exportWinningList({
        date: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      });
      if (res) {
        await this.getWinningFileDateStr();
        if (this.fileRecords.length) {
          location.href = this.fileRecords[0].file;
        }
      }
    },
    downloadWinFile(item) {
      //下载中奖文件
      location.href = item.file;
    },
    async getWinningFileDateStr() {
      //中奖导出历史记录
      const res = await getWinningFileDateStr();
      if (res) {
        this.fileRecords = res.map(item => {
          item.date = item.date.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2月$3日");
          return item;
        });
      }
    },
    async handleWinClick(num) {
      //设置奖项
      if (!this.winData.registIdList.length) {
        this.$showError("未选择中奖用户");
        return;
      }
      this.winData.amount = num;
      this.winData.date = this.queryData.date;
      const res = await editWinning(this.winData);
      if (res) {
        this.$showSuccess("设置成功");
        this.getJoinUserList();
      }
    },
    handleSortChange(e) {
      //排序
      if (e.order === "descending") {
        this.queryData.sortType = 2;
      } else if (e.order === "ascending") {
        this.queryData.sortType = 1;
      } else {
        this.queryData.sortType = null;
      }
      this.getJoinUserList();
    },
    handleSelectionChange(rows) {
      //选中
      this.winData.registIdList = rows.map(item => item.registId);
    },
    async getActivityDateStr() {
      //查询助力日期
      const res = await getActivityDateStr();
      if (res) {
        this.dateList = res.map(item => {
          return {
            label: item.replace(/(\d{4})-(\d{2})-(\d{2})/, "$2月$3日"),
            value: item
          };
        });
        this.queryData.date = res.slice(-1)[0];
        this.curDataLable = this.dateList[this.dateList.length - 1].label;
        this.getJoinUserList();
      }
    },
    async getJoinUserList() {
      //查询参与列表
      const res = await getJoinUserList(this.queryData);
      if (res) {
        const { list, total } = res;
        this.list = list || res;
        this.total = total;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.orange {
  color: #e6a23c;
}
.doanload-dialog {
  .g-btn {
    display: block;
    margin: 0 auto;
    padding: 20px 0 0 0;
  }
}
.downlost-list {
  display: flex;
  .item {
    width: 90px;
    height: 90px;
    border: 1px solid #eee;
    margin-right: 20px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    .svg {
      margin-bottom: 10px;
      font-size: 30px;
      color: #1296db;
    }
    &:hover {
      cursor: pointer;
      background: #f5f5f5;
    }
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  .l {
    padding-top: 20px;
  }
}
.tips {
  color: #999;
  margin-top: 8px;
}
.filter {
  display: flex;
  justify-content: space-between;
  .select {
    width: 150px;
    margin-right: 10px;
  }
}
</style>
